<template>

  <div class="about">
    <v-container grid-list-xs>
      <h1>TOKEN</h1> <span>Place this in your config</span>
      <v-spacer class="mb-4"></v-spacer>

      <v-alert
      v-if="warning"
      elevation="15"
      type="warning">

      <v-row align="center">
        <v-col class="grow"> You need to wait after each token regeneration!</v-col>
        <v-col class="grow"> <v-btn @click="warning = false" style="float: right" outlined>OKAY!</v-btn> </v-col>
      </v-row>
      </v-alert>

      <v-alert
      elevation="15"
      type="success">
      <div v-if="token == ''">

        <span>Loading </span>
        <v-progress-circular indeterminate color="white"></v-progress-circular>
      </div>

      <span v-if="token != ''" >{{token}}</span>

      </v-alert>

      <v-btn @click="generateNewToken()" outlined color="primary" dark>Regenerate</v-btn>

    </v-container>

  </div>

</template>

<script>


import axios from 'axios';

export default {
  name: 'dashboard',
  data() {
    return {
      lasttime: 'notset',
      warning: false,
      token: ''
    }
  },
  methods: {
    handleError(err) {
      this.$router.push({path: '/', query: { error: err }});
    },
    updateInfo() {

      axios.get('https://api.nolz.dev/api/data/token', {withCredentials:true,headers: {'Authorization': 'Basic ' + this.$cookies.get('authtoken')}}).then((val) => {
        console.log(val);
        this.token = val.data.token;
      })
      .catch((err) => {

        this.handleError(err.message);
      })

    },
    generateNewToken() {
      this.token = '';

    axios.get('https://api.nolz.dev/api/data/regenerate',{withCredentials:true, headers: {'Authorization': 'Basic ' + this.$cookies.get('authtoken')}}).then(val => {
      this.warning = false;
      this.token = val.data.token;
    })
    .catch((err) => {
        console.log(err.response.status);
        if(err.response.status === 429) {
          this.warning = true;
        }
        else {
          this.handleError(err.message);
        }


      })

    },
    checkCooldown() {
      if(this.lasttime == 'notset') {
          console.log(this.lasttime);
          const newDate = new Date();

          if(newDate.getSeconds() - this.lasttime.getSeconds() >= 5) {
            this.lasttime = newDate;
            this.generateNewToken
          }
          else {
            this.warning = true;
          }

      }
      else {
        this.generateNewToken();
        this.lasttime = new Date();
      }
    }
  },
  beforeCreate() {

  if (this.$route.query.code != undefined) {
    const code = this.$route.query.code;

    axios.get('https://api.nolz.dev/auth/token?code=' + code).then((val) => {
      console.log(val.data.token);
      this.$cookies.set('authtoken',val.data.token,"1d");
    }).catch((err) => {
      this.handleError(err.message);
    });



  }


  },
  created() {
    this.updateInfo();
  }
}
</script>